import {
  injectedMetaMaskProvider,
  injectedTallyProvider,
  walletconnect,
  walletlink,
} from "../connectors"

import { AbstractConnector } from "@web3-react/abstract-connector"
import { BigNumber } from "@ethersproject/bignumber"
// import alethLogo from "../assets/icons/sFtm_32.png"
import alusdLogo from "../assets/icons/geistfusdt_32.png"
import busdLogo from "../assets/icons/busd_32.png"
import coinbasewalletIcon from "../assets/icons/coinbasewallet.svg"
import daiLogo from "../assets/icons/dai.svg"
import feiLogo from "../assets/icons/mimlogopng.png"
import fraxLogo from "../assets/icons/frax_32_2.png"
import ftmLogo from "../assets/icons/wFtm_32.png"
import jioLPTokenLogo from "../assets/icons/logo.svg"
import jioLogo from "../assets/icons/logo.svg"
import lusdLogo from "../assets/icons/trueusd_32.png"
import metamaskIcon from "../assets/icons/metamask.svg"
// import mimLogo from "../assets/icons/mim.png"
// import nusdLogo from "../assets/icons/nusd.svg"
import renbtcLogo from "../assets/icons/renbtcpoly_32.png"
import sbtcLogo from "../assets/icons/wBTC_32.png"
import sethLogo from "../assets/icons/geistftm_32.png"
// import susdLogo from "../assets/icons/susd.svg"
import tallyIcon from "../assets/icons/tally.svg"
import tbtcLogo from "../assets/icons/fBTC_32.png"
import usdcLogo from "../assets/icons/usdc.svg"
import usdtLogo from "../assets/icons/fUSDT.png"
import veth2Logo from "../assets/icons/geisteth_32.png"
import walletconnectIcon from "../assets/icons/walletconnect.svg"
// import wbtcLogo from "../assets/icons/geistwbtc_32.png"
// import wcusdLogo from "../assets/icons/wcusd.png"
import wethLogo from "../assets/icons/weth.svg"

export const NetworkContextName = "NETWORK"
export const BTC_POOL_NAME = "BTC 4Pool"
export const BTC_POOL_V2_NAME = "BTC 2Pool"
export const STABLECOIN_POOL_NAME = "USD 3Pool v1"
export const STABLECOIN_POOL_V2_NAME = "USD 3Pool"
export const VETH2_POOL_NAME = "Ethereum Pool"
export const ALETH_POOL_NAME = "Fantom 3Pool"
export const D4_POOL_NAME = "USD 4Pool"
export const SUSD_METAPOOL_NAME = "sUSD Metapool"
export const SUSD_METAPOOL_V2_NAME = "sUSD Metapool V2"
export const TBTC_METAPOOL_NAME = "tBTC Metapool"
export const TBTC_METAPOOL_V2_NAME = "tBTC Metapool V2"
export const WCUSD_METAPOOL_NAME = "wCUSD Metapool"
export const WCUSD_METAPOOL_V2_NAME = "wCUSD Metapool V2"
export const ARB_USD_POOL_NAME = "arbUSD Pool"
export const OPT_USD_POOL_NAME = "optUSD Pool"
export const FRAX_OPT_USD_METAPOOL_NAME = "frax-optUSD Pool"
export const FRAX_ARB_USD_POOL_V2_NAME = "frax-ArbUSD Pool V2"
export const FTM_JIO_POOL_NAME = "FTM $JIO POOL"
export type PoolName =
  | typeof BTC_POOL_NAME
  | typeof BTC_POOL_V2_NAME
  | typeof STABLECOIN_POOL_NAME
  | typeof STABLECOIN_POOL_V2_NAME
  | typeof VETH2_POOL_NAME
  | typeof ALETH_POOL_NAME
  | typeof D4_POOL_NAME
  | typeof SUSD_METAPOOL_NAME
  | typeof SUSD_METAPOOL_V2_NAME
  | typeof TBTC_METAPOOL_NAME
  | typeof TBTC_METAPOOL_V2_NAME
  | typeof WCUSD_METAPOOL_NAME
  | typeof WCUSD_METAPOOL_V2_NAME
  | typeof ARB_USD_POOL_NAME
  | typeof OPT_USD_POOL_NAME
  | typeof FRAX_OPT_USD_METAPOOL_NAME
  | typeof FRAX_ARB_USD_POOL_V2_NAME
  | typeof FTM_JIO_POOL_NAME

export enum ChainId {
  // GW_V1_1 = 868455272153094,
  BSC_MAIN = 56,
  BSC_TEST = 97,
  FTM_MAIN = 250,
  FTM_TEST = 4002,
  OPTIMISM = 10,
  ETHEREUM = 1,
  AURORA = 1313161554,
  AURORA_TEST = 1313161555,
  EMERALD_TEST = 42261,
  ROPSTEN = 3,
  RINKEBY = 4,
  // GÖRLI = 5,
  // KOVAN = 42,
  HARDHAT = 31337,
  ARBITRUM = 42161,
  METER_TEST = 83,
  METER = 82,
  GW_V1_1 = 71401,
  THUNDERCORE = 18,
  DC_TEST = 568,
  VELAS = 111,
}
export enum PoolTypes {
  BTC,
  BSC,
  ETH,
  USD,
  OTHER,
}
const buildAddresses = (
  addresses: Partial<Record<ChainId, string>>,
): Record<ChainId, string> => {
  return Object.keys(ChainId).reduce((acc, id) => {
    const numId = Number(id) as ChainId
    return { ...acc, [numId]: addresses?.[numId] || "" }
  }, {}) as Record<ChainId, string>
}
const buildPids = (
  pids: Partial<Record<ChainId, number>>,
): Record<ChainId, number | null> => {
  // @dev be careful to include pid 0 in this boolean logic
  return Object.keys(ChainId).reduce((acc, id) => {
    const numId = Number(id) as ChainId
    const pid = pids[numId]
    return { ...acc, [numId]: pid == null ? null : pid }
  }, {}) as Record<ChainId, number | null>
}

export class Token {
  readonly addresses: { [chainId in ChainId]: string }
  readonly decimals: number
  readonly symbol: string
  readonly name: string
  readonly icon: string
  readonly geckoId: string
  readonly isSynthetic: boolean
  readonly isLPToken: boolean

  constructor(
    addresses: { [chainId in ChainId]: string },
    decimals: number,
    symbol: string,
    geckoId: string,
    name: string,
    icon: string,
    isSynthetic = false,
    isLPToken = false,
  ) {
    this.addresses = addresses
    this.decimals = decimals
    this.symbol = symbol
    this.geckoId = geckoId
    this.name = name
    this.icon = icon
    this.isSynthetic = isSynthetic
    this.isLPToken = isLPToken
  }
}
//tod deploy pools on all networks

export const BLOCK_TIME = 13000 // ms

export const SYNTHETIX_CONTRACT_ADDRESSES = buildAddresses({
  // // [ChainId.FTM_MAIN]: "",
  // [ChainId.FTM_TEST]: "",
})

export const SYNTHETIX_EXCHANGE_RATES_CONTRACT_ADDRESSES = buildAddresses({
  // // [ChainId.FTM_MAIN]: "",
})

export const BRIDGE_CONTRACT_ADDRESSES = buildAddresses({
  // // [ChainId.FTM_MAIN]: "",
})

export const MINICHEF_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.BSC_TEST]: "0x17F636FFe441808a87FD70cA0Fbc8ee09D761Fc9",
  [ChainId.GW_V1_1]: "0xab3b6FC1B2FA233E4a8b0B1Fc1F221eA6e1a38BE",
  [ChainId.RINKEBY]: "0x63227ee8c98d91405BB6446267eA2C5b5E807bFb",
  [ChainId.AURORA_TEST]: "0xAFBB4EE603E508C1826eBce566EAc56a4908B417",
  [ChainId.EMERALD_TEST]: "0x7e8385A47b7A13eb7f83e2cD4a26F05D6076574e",
  [ChainId.METER_TEST]: "0x824bEE671ae37Bf583E18A7E1060A902aE2FdAaA",
  [ChainId.THUNDERCORE]: "0x555C3893b7607BA024E967053F250eEca7eD14d7",
  [ChainId.DC_TEST]: "0x38205f8c6f285289398bc20e0068081E575c63C6",
  [ChainId.VELAS]: "0x7af1A0DbCD42D5072ad7c1972B33D370ae2d980E",
})

export const SWAP_MIGRATOR_USD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.GW_V1_1]: "0xFfc39839480251D9f9472D31c189dA4C57e266Da",
  [ChainId.THUNDERCORE]: "0x55D41427B1f43b1EcA41b0B96390FB4aa84b7626",
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const GENERALIZED_SWAP_MIGRATOR_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.GW_V1_1]: "0x8ed19a50b56184555B4b0FECc2da935B6E5c4E35",
  [ChainId.THUNDERCORE]: "0x6ED8d14D6B55c036AD2963c4098817c0b08b0A40",
  // // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const SUSD_META_SWAP_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const SUSD_META_SWAP_V2_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const SUSD_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const SUSD_META_SWAP_V2_DEPOSIT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const TBTC_META_SWAP_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const TBTC_META_SWAP_V2_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const TBTC_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const TBTC_META_SWAP_V2_DEPOSIT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const WCUSD_META_SWAP_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const WCUSD_META_SWAP_V2_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const WCUSD_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const WCUSD_META_SWAP_V2_DEPOSIT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})
export const FRAX_OPT_USD_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  // [ChainId.OPTIMISM]: "",
})

export const FTM_JIO_SWAP_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
})

export const STABLECOIN_SWAP_ADDRESSES = buildAddresses({
  [ChainId.GW_V1_1]: "0xbB463d2f58B45Cf59F53323099f90EfC95ED21FE",
  [ChainId.THUNDERCORE]: "0x36f9A7a0c03A2B5a5DCc08176b222618caC474a9",
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
  // [ChainId.BSC_TEST]: "",
})

export const STABLECOIN_SWAP_V2_ADDRESSES = buildAddresses({
  [ChainId.BSC_TEST]: "0xc44478558b33c503566BF7D14d0996E942352f80",
  [ChainId.GW_V1_1]: "0x24b57Cb98B8b588E52cfA67F5211e6D0FE58eCCa",
  [ChainId.AURORA_TEST]: "0x825f8Af05a4D39cC10Bfe0485DE17fC2EF2765eD",
  [ChainId.FTM_TEST]: "0x07A31A5798a54EC519246A651B091f60Ce8e8404",
  [ChainId.RINKEBY]: "0xf0A88992cCb6D607cbE599581187a2878046eD78",
  [ChainId.EMERALD_TEST]: "0xC063dc6B24d6b9a45E891239E6670117031D8fb1",
  [ChainId.METER_TEST]: "0x3c32a20087F31978b2126F32833458033439EC4d",
  [ChainId.THUNDERCORE]: "0xEd2a1d05c9fA4a2c4165C887e022c29a41B4C64c",
  [ChainId.DC_TEST]: "0x4103aE143F839c7AAe8c559b5C1975BBEf5F8528",
  [ChainId.VELAS]: "0x4103aE143F839c7AAe8c559b5C1975BBEf5F8528",
})

export const BTC_SWAP_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const BTC_SWAP_V2_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  [ChainId.BSC_TEST]: "0xeA3B41c42FA69FC02Ab97AFA7bA7B663a2B905cc", // TODO: add address after deployment
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const VETH2_SWAP_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.FTM_TEST]: "",
})

export const ALETH_SWAP_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.FTM_TEST]: "",
})

export const D4_SWAP_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  [ChainId.BSC_TEST]: "0x82a53AAF7A3A60FCA71171D8C305C97c387357FA",
  [ChainId.GW_V1_1]: "0x71b5e58E1a207eAc5B2d279506c6B2dBc6E2E8DE",
  [ChainId.FTM_TEST]: "0x9790306Fd108DdEF81B06AAB21dD79c362BD15ab",
  [ChainId.RINKEBY]: "0xA322D0b95c4F051416d8c6B15Ad189152C43839A",
  [ChainId.AURORA_TEST]: "0xECab15b6710FB34b52bEf36546C097eF4F5e034C",
  [ChainId.EMERALD_TEST]: "0xb0e46279859906EDB715dDAB177B2c9d0c99a42e",
  [ChainId.METER_TEST]: "0xcbb5D150A9D1F633eB47AE11070eC59Cb71A6d2f",
  [ChainId.THUNDERCORE]: "0x9A366FF85207DD13a8689b02de855b7Ba0Ef3911",
  [ChainId.DC_TEST]: "0x1e5A7F226552e05b222fF38C1B51304869dD13f2",
  [ChainId.VELAS]: "0x1e5A7F226552e05b222fF38C1B51304869dD13f2",
})

export const ARB_USD_SWAP_ADDRESSES = buildAddresses({
  // [ChainId.ARBITRUM]: "",
})

export const OPT_USD_SWAP_ADDRESSES = buildAddresses({
  // [ChainId.OPTIMISM]: "",
})

export const FRAX_OPT_USD_SWAP_ADDRESSES = buildAddresses({
  // [ChainId.OPTIMISM]: "",
})
export const FRAX_ARB_USD_SWAP_V2_ADDRESSES = buildAddresses({
  // [ChainId.ARBITRUM]: "",
})

// export const RETROACTIVE_JIO_MERKLETREE_DATA = buildAddresses({
//   [ChainId.HARDHAT]: "hardhat.json",
// })

export const SUSD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const SUSD_SWAP_TOKEN_V2_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const FTM_JIO_SWAP_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.FTM_MAIN]: "",
})

export const STABLECOIN_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.GW_V1_1]: "0xa45829C907eE446128aaB91BD91c66BC38803449",
  [ChainId.THUNDERCORE]: "0x08dd3ea0E70EC2a8b622B72Ed727948B626d6FBd",
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const STABLECOIN_SWAP_V2_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  [ChainId.BSC_TEST]: "0x721117C077DF139c105e76e60B81905574477FCD",
  [ChainId.GW_V1_1]: "0x74C02D33710dFa4926F65ad3941EFdb2a74644D2",
  [ChainId.FTM_TEST]: "0x036CADABB29894988bA61Cb641C2b6CbC82Cea9f",
  [ChainId.RINKEBY]: "0xd47116FEeF97FCB2D57260DF6CA10dba7A6175c9",
  [ChainId.AURORA_TEST]: "0x522De7f805E79e008BC51e3E2B65480F4230fa1A",
  [ChainId.EMERALD_TEST]: "0xeDFD35F8150119429e5CfF4B6c6148b26F93ddaA",
  [ChainId.METER_TEST]: "0xAFD987b06662694B1d2F0e25DafD8b030DE56123",
  [ChainId.THUNDERCORE]: "0x3968846F41049Ee028c265a31E506eE7C04ECcE8",
  [ChainId.DC_TEST]: "0xe3A38066918C80E6be01AB83fa2416dE2889Ac6E",
  [ChainId.VELAS]: "0xe3A38066918C80E6be01AB83fa2416dE2889Ac6E",
})

export const WCUSD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const WCUSD_SWAP_TOKEN_V2_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
})

export const ARB_USD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.ARBITRUM]: "",
})
export const OPT_USD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.OPTIMISM]: "",
})

export const FRAX_OPT_USD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.OPTIMISM]: "",
})

export const FRAX_ARB_USD_SWAP_V2_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.ARBITRUM]: "",
})

export const BTC_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const BTC_SWAP_V2_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  [ChainId.BSC_TEST]: "0xE9f137f884Bf8E4233Fe49179f905cc50ae6E20f", // TODO: add address after deployment
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const TBTC_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const TBTC_SWAP_TOKEN_V2_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const VETH2_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0x34a507Eae778b1a30b2ABbFBA6A23ff94656d357",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const ALETH_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})

export const D4_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  [ChainId.BSC_TEST]: "0x2Bf6C4c318b672A521A8eE870573a9aFa77E8E73",
  [ChainId.GW_V1_1]: "0x7487D766eC76c92B26C16297807F8Cd12837ab6e",
  [ChainId.FTM_TEST]: "0xbBc575276C6f0A0E485206ADd224d30A7d53a4ca",
  [ChainId.RINKEBY]: "0x2FfC6e701D3CE38806725F70876bE18084e6A48B",
  [ChainId.AURORA_TEST]: "0x45C797e55D5316aE6fF6334b6eCa41C9729C392D",
  [ChainId.EMERALD_TEST]: "0xdEE02B18617597Ab33E3fBe3822CBd3FbE6Cee53",
  [ChainId.METER_TEST]: "0xbf415387d113fd5Ef74B6d756e1d9d8657A4902d",
  [ChainId.THUNDERCORE]: "0x8664aABa98F5466A46Ec5cA7b9c83469991f9cf1",
  [ChainId.DC_TEST]: "0x53cB4de202868512b77FCCF18Df1216874978171",
  [ChainId.VELAS]: "0x53cB4de202868512b77FCCF18Df1216874978171",
})

export const JIO_TOKEN_ADDRESSES = buildAddresses({
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_MAIN]: "",
  [ChainId.BSC_TEST]: "0x5Cf239360F452273d3dF71366E54C349f9cDa411",
  [ChainId.GW_V1_1]: "0xb5727223892A38d2Ef7Ca7365dcaC9970A63E82e",
  [ChainId.FTM_TEST]: "0xA579Fe24520180f9A42Eb00761b9E72aaAA25C95",
  [ChainId.RINKEBY]: "0x4e613A4560F7fE1f75314eE1486fA2FFffB729f7",
  [ChainId.AURORA_TEST]: "0x23bA439C9489Cc00de7Cde53DC7bBf44aD2E82Ba",
  [ChainId.EMERALD_TEST]: "0xd0d796710d05FF71040B6dfb965Bd73e87b7F3B7",
  [ChainId.METER_TEST]: "0x57119CF1030F9845d96567da8F8C3e41B4303aaD",
  [ChainId.THUNDERCORE]: "0x01fD12a9a08299Cf3B67C558f73f7f840C34A0a2",
  [ChainId.DC_TEST]: "0x011Ce274EdfaFC06C820045Df8de8e55BCDD2432",
  [ChainId.VELAS]: "0x6025A2Cf0596bB7B6214FfdF018EA8585eeAaF6A",

  // [ChainId.FTM_TEST]: "",
  // [ChainId.FTM_TEST]: "",
})

export const CKB_TOKEN_ADDRESSES = buildAddresses({
  // [ChainId.GW_V1_1]: "0x86efaff75201Ed513c2c9061f2913eec850af56C",
})

export const OPT_USD_SWAP_TOKEN = new Token(
  OPT_USD_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "jioOptUSD",
  "jiooptUSD",
  "Jio DAI/USDC/USDT",
  jioLogo,
  false,
  true,
)

export const FRAX_OPT_USD_SWAP_TOKEN = new Token(
  FRAX_OPT_USD_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "frax-jioOptUSD",
  "frax-jiooptUSD",
  "Jio FRAX/jioOptUsd",
  jioLogo,
  false,
  true,
)

export const JIO_TOKEN = new Token(
  JIO_TOKEN_ADDRESSES,
  18,
  "JIO",
  "jio", // TBD
  "Jioswap",
  jioLogo,
  false,
  false,
)
export const CKB_TOKEN = new Token(
  CKB_TOKEN_ADDRESSES,
  8,
  "CKB",
  "nervos-network", // TBD
  "CKB",
  jioLogo,
  false,
  false,
)

export const FTM_JIO_SWAP_TOKEN = new Token(
  FTM_JIO_SWAP_TOKEN_ADDRESSES,
  18,
  "jioFTM",
  "jiosusd",
  "Jio/FTM",
  jioLPTokenLogo,
  false,
  true,
)

// export const SUSD_SWAP_V2_TOKEN = new Token(
//   SUSD_SWAP_TOKEN_V2_CONTRACT_ADDRESSES,
//   18,
//   "jioSUSD-V2",
//   "jiosusd-v2",
//   "Jio sUSD/jioUSD-V2 V2",
//   jioLPTokenLogo,
//   false,
//   true,
// )

export const BTC_SWAP_TOKEN = new Token(
  BTC_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "jioFWRenBTC",
  "jiobtc",
  "Jio fBTC/WBTC/renBTC/BTC",
  jioLPTokenLogo,
  false,
  true,
)

export const BTC_SWAP_V2_TOKEN = new Token(
  BTC_SWAP_V2_TOKEN_CONTRACT_ADDRESSES,
  18,
  "jioBTC",
  "bitcoin",
  "Jio anyBTC/BTCB",
  jioLPTokenLogo,
  false,
  true,
)

// export const TBTC_SWAP_TOKEN = new Token(
//   TBTC_SWAP_TOKEN_CONTRACT_ADDRESSES,
//   18,
//   "jiotBTC",
//   "jiotBTC",
//   "Jio tBTCv2/jioWRenSBTC",
//   jioLPTokenLogo,
//   false,
//   true,
// )

// export const TBTC_SWAP_V2_TOKEN = new Token(
//   TBTC_SWAP_TOKEN_V2_CONTRACT_ADDRESSES,
//   18,
//   "jiotBTC-V2",
//   "jiotBTC-v2",
//   "Jio tBTCv2/jioWRenSBTC V2",
//   jioLPTokenLogo,
//   false,
//   true,
// )

export const STABLECOIN_SWAP_TOKEN = new Token(
  STABLECOIN_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "jioUSD",
  "usd-coin",
  "Jio DAI/USDC/BUSD",
  jioLPTokenLogo,
  false,
  true,
)

export const STABLECOIN_SWAP_V2_TOKEN = new Token(
  STABLECOIN_SWAP_V2_TOKEN_CONTRACT_ADDRESSES,
  18,
  "jioUSD",
  "usd-coin",
  "Jio DAI/USDC/BUSD",
  jioLPTokenLogo,
  false,
  true,
)

// export const WCUSD_SWAP_TOKEN = new Token(
//   WCUSD_SWAP_TOKEN_CONTRACT_ADDRESSES,
//   18,
//   "jiowCUSD",
//   "jiowcusd",
//   "Jio wCUSD/jioUSD-V2",
//   jioLPTokenLogo,
//   false,
//   true,
// )

// export const WCUSD_SWAP_V2_TOKEN = new Token(
//   WCUSD_SWAP_TOKEN_V2_CONTRACT_ADDRESSES,
//   18,
//   "jiowCUSD-V2",
//   "jiowcusd-v2",
//   "Jio wCUSD/jioUSD-V2 V2",
//   jioLPTokenLogo,
//   false,
//   true,
// )

// export const ARB_USD_SWAP_TOKEN = new Token(
//   ARB_USD_SWAP_TOKEN_CONTRACT_ADDRESSES,
//   18,
//   "jioArbUSD",
//   "jioarbUSD",
//   "Jio nUSD/MIM/USDC/USDT",
//   jioLogo,
//   false,
//   true,
// )

// export const FRAX_ARB_USD_SWAP_V2_TOKEN = new Token(
//   FRAX_ARB_USD_SWAP_V2_TOKEN_CONTRACT_ADDRESSES,
//   18,
//   "jioFraxArbUSD-V2",
//   "jiofraxarbUSD-v2",
//   "Jio FRAX/USDC/USDT",
//   jioLogo,
//   false,
//   true,
// )

export const VETH2_SWAP_TOKEN = new Token(
  VETH2_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "jioETH",
  "ethereum",
  "Jio ETH/gETH",
  jioLPTokenLogo,
  false,
  true,
)

export const ALETH_SWAP_TOKEN = new Token(
  ALETH_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "jioFTM",
  "fantom",
  "Jio WFTM/gFTM",
  jioLPTokenLogo,
  false,
  true,
)

export const D4_SWAP_TOKEN = new Token(
  D4_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "jio4USD",
  "usd-coin",
  "Jio BUSD/DAI/USDC/USDT",
  jioLPTokenLogo,
  false,
  true,
)

// Stablecoins
// const WCUSD_CONTRACT_ADDRESSES = buildAddresses({
//   // [ChainId.FTM_MAIN]: "",
//   [ChainId.HARDHAT]: "",
//   [ChainId.FTM_TEST]: "",
// })
// export const WCUSD = new Token(
//   WCUSD_CONTRACT_ADDRESSES,
//   18,
//   "wCUSD",
//   "wrapped-celo-dollar",
//   "Wrapped Celo USD",
//   lusdLogo,
// )

// const SUSD_CONTRACT_ADDRESSES = buildAddresses({
//   // [ChainId.FTM_MAIN]: "",
//   [ChainId.HARDHAT]: "",
//   [ChainId.FTM_TEST]: "",
// })
// export const SUSD = new Token(
//   SUSD_CONTRACT_ADDRESSES,
//   18,
//   "sUSD",
//   "nusd",
//   "sUSD",
//   lusdLogo,
//   true,
// )

const DAI_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  [ChainId.BSC_TEST]: "0xb67f7a78eD4086Fa76037Ac73d07Ba1132C02756",
  [ChainId.GW_V1_1]: "0x8aab5FE56f0aD4500BD8e3aE1Fc84ec4c634fE4C",
  // [ChainId.HARDHAT]: "",
  [ChainId.FTM_TEST]: "0xA64c2a4470708c4709654Dd0363D3A48F9bB656c",
  [ChainId.RINKEBY]: "0xB73fBd0c9198455473de7426923183167A32D05e",
  [ChainId.AURORA_TEST]: "0x9c8F52D054aD8A3992bA34c000A4Ec1E0aE4FcFE",
  [ChainId.EMERALD_TEST]: "0x19036dB19741F242DAc16818301249dAFf2DE454",
  [ChainId.METER_TEST]: "0x6D942D2503cDcf996f6B7319187Caea7C9e19107",
  [ChainId.THUNDERCORE]: "0x57e171856D7A7C38Eaed2A1440Ffb69Dd53e3039",
  [ChainId.DC_TEST]: "0xf2fC6426688771F4dCA5eFeD7C9C3484FA65520B",
  [ChainId.VELAS]: "0xf2fC6426688771F4dCA5eFeD7C9C3484FA65520B",
})
export const DAI = new Token(
  DAI_CONTRACT_ADDRESSES,
  18,
  "DAI",
  "dai",
  "Dai",
  daiLogo,
)

const USDC_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  [ChainId.BSC_TEST]: "0x0a6fA36cc3b627680131f48182048c16eb510Bd1",
  [ChainId.GW_V1_1]: "0x11A3893836e2D723E0326457E5e9E2466281FEE5",
  // [ChainId.HARDHAT]: "",
  // [ChainId.ARBITRUM]: "",
  [ChainId.FTM_TEST]: "0xdACE8b0f5B51F5C36dAFA073967DE7bda2d39bAc",
  [ChainId.RINKEBY]: "0xd0d796710d05FF71040B6dfb965Bd73e87b7F3B7",
  [ChainId.AURORA_TEST]: "0x44Ff46ED7361943D4dE2E9F5CC4Aef5cE30b45C4",
  [ChainId.EMERALD_TEST]: "0x95a995b040C1a7389b929146DcD83C4c31f41A9F",
  [ChainId.METER_TEST]: "0x55E6942310d9644c36a4fe33943c4a1380aCdd53",
  [ChainId.THUNDERCORE]: "0x80485B20175d6708D2dFEa8d0314D10cC05E7d89",
  [ChainId.DC_TEST]: "0x29962A970a64293DD07d08C4eb5f460bAe2f673f",
  [ChainId.VELAS]: "0x29962A970a64293DD07d08C4eb5f460bAe2f673f",
})
export const USDC = new Token(
  USDC_CONTRACT_ADDRESSES,
  18,
  "USDC",
  "usd-coin",
  "USDC Coin",
  usdcLogo,
)

const USDT_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  [ChainId.BSC_TEST]: "0xAd38836d7824AB173EDdb2705a07a98a9e8E5Ffc",
  [ChainId.GW_V1_1]: "0xA2a85cB98bF8ad7B8CA510bc998F8D15a1aeD46d",
  // [ChainId.HARDHAT]: "",
  // [ChainId.ARBITRUM]: "",
  [ChainId.FTM_TEST]: "0xfDe53bc19Ed094Dfa234E9789Ed938F009495C88",
  [ChainId.RINKEBY]: "0x5DF0a3e88D042e84f8417A8aBA89d94Cad425de7",
  [ChainId.AURORA_TEST]: "0xCb78bd0E16441dBf5c77B49dD5E1Bcef335AffEe",
  [ChainId.EMERALD_TEST]: "0x4b9C66D08960C5785E698F934FBbBaFd2E605A53",
  [ChainId.METER_TEST]: "0x0fea846C1A01E1c30689cb3CcbA74C8F3183e43e",
  [ChainId.THUNDERCORE]: "0xC2AB36719B1E79Ce3AEC3f434A6b2D3034fB8e78",
  [ChainId.DC_TEST]: "0x48d84AEB6C3ED59199CA704F408bf977AD12BBBc",
  [ChainId.VELAS]: "0x48d84AEB6C3ED59199CA704F408bf977AD12BBBc",
})
export const USDT = new Token(
  USDT_CONTRACT_ADDRESSES,
  18,
  "USDT",
  "tether",
  "USDT",
  usdtLogo,
)

const BUSD_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  [ChainId.BSC_TEST]: "0x4F1Ae7e332A3Df20dD3a5Eb493695A6EcFC00E82",
  [ChainId.GW_V1_1]: "0x31D56EA29aEded3A17f0304301D5d4E3074d6049",
  // [ChainId.HARDHAT]: "",
  // [ChainId.ARBITRUM]: "",
  [ChainId.FTM_TEST]: "0x70eFd867D93EEd9CF2367Fa9F3F80794744530d0",
  [ChainId.RINKEBY]: "0x7e8385A47b7A13eb7f83e2cD4a26F05D6076574e",
  [ChainId.AURORA_TEST]: "0xe68362c5d9AEa52fE7882BA2a04f1F40BD93FfC4",
  [ChainId.EMERALD_TEST]: "0x4aF90cF00DC74062c33F9Fd17Ba8aA8AB85379dA",
  [ChainId.METER_TEST]: "0xA7c7AC379c8C8d8dD50AEE80B6985De4651bCf71",
  [ChainId.THUNDERCORE]: "0x6b7c17e1C34c4a7352D8799F874b672085Be03A5",
  [ChainId.DC_TEST]: "0x06F8606e6721084598d8be58ac0F8Cd800812bfA",
  [ChainId.VELAS]: "0x06F8606e6721084598d8be58ac0F8Cd800812bfA",
})
export const BUSD = new Token(
  BUSD_CONTRACT_ADDRESSES,
  18,
  "BUSD",
  "binance-usd",
  "BUSD Token",
  busdLogo,
)

// const NUSD_CONTRACT_ADDRESSES = buildAddresses({
//   [ChainId.ARBITRUM]: "0x2913e812cf0dcca30fb28e6cac3d2dcff4497688",
// })
// export const NUSD = new Token(
//   NUSD_CONTRACT_ADDRESSES,
//   18,
//   "nUSD",
//   "nusd",
//   "nUSD",
//   nusdLogo,
// )

// const MIM_CONTRACT_ADDRESSES = buildAddresses({
//   [ChainId.ARBITRUM]: "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
// })
// export const MIM = new Token(
//   MIM_CONTRACT_ADDRESSES,
//   18,
//   "MIM",
//   "magic-internet-money",
//   "Magic Internet Money",
//   mimLogo,
// )
export const STABLECOIN_POOL_TOKENS = [DAI, USDC, BUSD]

// export const SUSD_POOL_TOKENS = [SUSD, ...STABLECOIN_POOL_TOKENS]
// export const SUSD_UNDERLYING_POOL_TOKENS = [SUSD, STABLECOIN_SWAP_V2_TOKEN]
// export const ARB_USD_POOL_TOKENS = [NUSD, MIM, USDC, USDT]

// Tokenized BTC
const TBTC_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0x7eCAc22DE8c6C4Cf72C0EBe7FF9eBAc267CF0584",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const TBTC = new Token(
  TBTC_CONTRACT_ADDRESSES,
  18,
  "FBTC",
  "tbtc",
  "fBTC",
  tbtcLogo,
)

// const TBTC_V2_CONTRACT_ADDRESSES = buildAddresses({
//   // [ChainId.FTM_MAIN]: "",
//   [ChainId.HARDHAT]: "",
//   // [ChainId.FTM_TEST]: "",
// })
// export const TBTC_V2 = new Token(
//   TBTC_V2_CONTRACT_ADDRESSES,
//   18,
//   "TBTCv2",
//   "tbtc",
//   "tBTCv2",
//   tbtcLogo,
// )

const JIO_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const JIO = new Token(
  JIO_CONTRACT_ADDRESSES,
  18,
  "JIO",
  "jio",
  "Jioswap",
  jioLogo,
)

const RENBTC_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0xDBf31dF14B66535aF65AaC99C32e9eA844e14501",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const RENBTC = new Token(
  RENBTC_CONTRACT_ADDRESSES,
  8,
  "RENBTC",
  "renbtc",
  "renBTC",
  renbtcLogo,
)
const ANYBTC_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0xDBf31dF14B66535aF65AaC99C32e9eA844e14501",
  [ChainId.BSC_TEST]: "0xAc17039EEAc131F10F5409238E7edf8B45f8Bf6c",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const ANYBTC = new Token(
  ANYBTC_CONTRACT_ADDRESSES,
  8,
  "anyBTC",
  "bitcoin",
  "ANY Bitcoin",
  renbtcLogo,
)

const BTCB_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0x321162Cd933E2Be498Cd2267a90534A804051b11",
  [ChainId.BSC_TEST]: "0x77C3d003dF3465dda7AA4Cfd75e77741394B0542",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const BTCB = new Token(
  BTCB_CONTRACT_ADDRESSES,
  18,
  "BTCB",
  "bitcoin",
  "Binance-Peg BTCB Token",
  sbtcLogo,
  true,
)

const SBTC_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const SBTC = new Token(
  SBTC_CONTRACT_ADDRESSES,
  8,
  "BTC",
  "sbtc",
  "Bitcoin",
  sbtcLogo,
  true,
)

export const BTC_POOL_TOKENS = [RENBTC, SBTC]
export const BTC_POOL_V2_TOKENS = [ANYBTC, BTCB]

// export const TBTC_POOL_TOKENS = [TBTC_V2, ...BTC_POOL_V2_TOKENS]
// export const TBTC_UNDERLYING_POOL_TOKENS = [TBTC_V2, BTC_SWAP_V2_TOKEN]

const WETH_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const WETH = new Token(
  WETH_CONTRACT_ADDRESSES,
  18,
  "WETH",
  "ethereum",
  "WETH",
  wethLogo,
)
const WFTM_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const WFTM = new Token(
  WFTM_CONTRACT_ADDRESSES,
  18,
  "WFTM",
  "fantom",
  "Wrapped Fantom",
  ftmLogo,
)

const VETH2_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0x25c130B2624CF12A4Ea30143eF50c5D68cEFA22f",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const VETH2 = new Token(
  VETH2_CONTRACT_ADDRESSES,
  18,
  "gETH",
  "ethereum",
  "Geist ETH",
  veth2Logo,
)

export const VETH2_POOL_TOKENS = [WETH, VETH2]

const GFTM_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0x39B3bd37208CBaDE74D0fcBDBb12D606295b430a",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const GFTM = new Token(
  GFTM_CONTRACT_ADDRESSES,
  18,
  "gFTM",
  "geist-ftm",
  "Geist FTM",
  sethLogo,
  true,
)

export const ALETH_POOL_TOKENS = [WFTM, GFTM]

const GFUSDT_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.FTM_MAIN]: "0x940F41F0ec9ba1A34CF001cc03347ac092F5F6B5",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const GFUSDT = new Token(
  GFUSDT_CONTRACT_ADDRESSES,
  6,
  "gfUSDT",
  "alchemix-usd",
  "Geist fUSDT",
  alusdLogo,
)

const MIM_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0x82f0B8B456c1A451378467398982d4834b6829c1",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const MIM = new Token(
  MIM_CONTRACT_ADDRESSES,
  18,
  "MIM",
  "magic-internet-money",
  "Magic Internet Money",
  feiLogo,
)

const FRAX_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.ARBITRUM]: "",
  // [ChainId.FTM_TEST]: "",
})
export const FRAX = new Token(
  FRAX_CONTRACT_ADDRESSES,
  18,
  "FRAX",
  "frax",
  "Frax",
  fraxLogo,
)
export const OPT_USD_POOL_TOKENS = [DAI, USDC, USDT]
export const FRAX_OPT_USD_POOL_TOKENS = [FRAX, ...OPT_USD_POOL_TOKENS]
export const FRAX_OPT_USD_UNDERLYING_POOL_TOKENS = [FRAX, OPT_USD_SWAP_TOKEN]
export const FTM_JIO_POOL_TOKENS = [JIO, WFTM]
// export const FRAX_ARB_USD_POOL_V2_TOKENS = [FRAX, USDC, USDT]

const TUSD_CONTRACT_ADDRESSES = buildAddresses({
  // [ChainId.FTM_MAIN]: "0x9879aBDea01a879644185341F7aF7d8343556B7a",
  // [ChainId.ROPSTEN]: "",
  // [ChainId.HARDHAT]: "",
  // [ChainId.FTM_TEST]: "",
})
export const TUSD = new Token(
  TUSD_CONTRACT_ADDRESSES,
  18,
  "TUSD",
  "true-usd",
  "TrueUSD",
  lusdLogo,
)

export const D4_POOL_TOKENS = [BUSD, DAI, USDC, USDT]

// export const WCUSD_POOL_TOKENS = [WCUSD, ...STABLECOIN_POOL_TOKENS]
// export const WCUSD_UNDERLYING_POOL_TOKENS = [WCUSD, STABLECOIN_SWAP_V2_TOKEN]

export type Pool = {
  name: PoolName
  lpToken: Token
  poolTokens: Token[]
  isSynthetic: boolean
  addresses: { [chainId in ChainId]: string }
  type: PoolTypes
  route: string
  metaSwapAddresses?: { [chainId in ChainId]: string }
  underlyingPoolTokens?: Token[]
  underlyingPool?: PoolName
  isOutdated?: boolean // pool can be outdated but not have a migration target
  rewardPids: { [chainId in ChainId]: number | null }
}
export type PoolsMap = {
  [poolName: string]: Pool
}
export const POOLS_MAP: PoolsMap = {
  [FTM_JIO_POOL_NAME]: {
    name: FTM_JIO_POOL_NAME,
    addresses: FTM_JIO_SWAP_ADDRESSES,
    lpToken: FTM_JIO_SWAP_TOKEN,
    poolTokens: FTM_JIO_POOL_TOKENS,
    isSynthetic: false,
    type: PoolTypes.ETH,
    route: "jioftm",
    rewardPids: buildPids({}),
  },
  [BTC_POOL_NAME]: {
    name: BTC_POOL_NAME,
    addresses: BTC_SWAP_ADDRESSES,
    lpToken: BTC_SWAP_TOKEN,
    poolTokens: BTC_POOL_TOKENS,
    isSynthetic: true,
    type: PoolTypes.BTC,
    route: "btc",
    isOutdated: true,
    rewardPids: buildPids({}),
  },

  [OPT_USD_POOL_NAME]: {
    name: OPT_USD_POOL_NAME,
    addresses: OPT_USD_SWAP_ADDRESSES,
    lpToken: OPT_USD_SWAP_TOKEN,
    poolTokens: OPT_USD_POOL_TOKENS,
    isSynthetic: false,
    type: PoolTypes.USD,
    route: "optusd",
    rewardPids: buildPids({}),
  },
  [FRAX_OPT_USD_METAPOOL_NAME]: {
    name: FRAX_OPT_USD_METAPOOL_NAME,
    addresses: FRAX_OPT_USD_META_SWAP_DEPOSIT_ADDRESSES,
    lpToken: FRAX_OPT_USD_SWAP_TOKEN,
    poolTokens: FRAX_OPT_USD_POOL_TOKENS,
    isSynthetic: false,
    type: PoolTypes.USD,
    metaSwapAddresses: FRAX_OPT_USD_SWAP_ADDRESSES,
    underlyingPoolTokens: FRAX_OPT_USD_UNDERLYING_POOL_TOKENS,
    underlyingPool: OPT_USD_POOL_NAME,
    route: "frax-optusd",
    rewardPids: buildPids({}),
  },
  [BTC_POOL_V2_NAME]: {
    name: BTC_POOL_V2_NAME,
    addresses: BTC_SWAP_V2_ADDRESSES,
    lpToken: BTC_SWAP_V2_TOKEN,
    poolTokens: BTC_POOL_V2_TOKENS,
    isSynthetic: true,
    type: PoolTypes.BTC,
    route: "btcv2",
    rewardPids: buildPids({ [ChainId.BSC_TEST]: 3 }),
  },
  [STABLECOIN_POOL_NAME]: {
    name: STABLECOIN_POOL_NAME,
    addresses: STABLECOIN_SWAP_ADDRESSES,
    lpToken: STABLECOIN_SWAP_TOKEN,
    poolTokens: STABLECOIN_POOL_TOKENS,
    isSynthetic: false,
    type: PoolTypes.USD,
    route: "usd",
    isOutdated: true,
    rewardPids: buildPids({
      [ChainId.GW_V1_1]: 2,
    }),
  },
  [STABLECOIN_POOL_V2_NAME]: {
    name: STABLECOIN_POOL_V2_NAME,
    addresses: STABLECOIN_SWAP_V2_ADDRESSES,
    lpToken: STABLECOIN_SWAP_V2_TOKEN,
    poolTokens: STABLECOIN_POOL_TOKENS,
    isSynthetic: false,
    type: PoolTypes.USD,
    route: "usdv2",
    rewardPids: buildPids({
      [ChainId.BSC_TEST]: 2,
      [ChainId.GW_V1_1]: 2,
      [ChainId.FTM_TEST]: 2,
      [ChainId.RINKEBY]: 2,
      [ChainId.AURORA_TEST]: 2,
      [ChainId.EMERALD_TEST]: 2,
      [ChainId.METER_TEST]: 2,
      [ChainId.DC_TEST]: 2,
      [ChainId.THUNDERCORE]: 3,
      [ChainId.VELAS]: 2,
    }),
  },
  [VETH2_POOL_NAME]: {
    name: VETH2_POOL_NAME,
    addresses: VETH2_SWAP_ADDRESSES,
    lpToken: VETH2_SWAP_TOKEN,
    poolTokens: VETH2_POOL_TOKENS,
    isSynthetic: false,
    type: PoolTypes.ETH,
    route: "veth2",
    rewardPids: buildPids({}),
  },
  [ALETH_POOL_NAME]: {
    name: ALETH_POOL_NAME,
    addresses: ALETH_SWAP_ADDRESSES,
    lpToken: ALETH_SWAP_TOKEN,
    poolTokens: ALETH_POOL_TOKENS,
    isSynthetic: false,
    type: PoolTypes.ETH,
    route: "aleth",
    rewardPids: buildPids({}),
  },
  [D4_POOL_NAME]: {
    name: D4_POOL_NAME,
    addresses: D4_SWAP_ADDRESSES,
    lpToken: D4_SWAP_TOKEN,
    poolTokens: D4_POOL_TOKENS,
    isSynthetic: false,
    type: PoolTypes.USD,
    route: "usd4",
    rewardPids: buildPids({
      [ChainId.BSC_TEST]: 1,
      [ChainId.GW_V1_1]: 1,
      [ChainId.FTM_TEST]: 1,
      [ChainId.RINKEBY]: 1,
      [ChainId.AURORA_TEST]: 1,
      [ChainId.EMERALD_TEST]: 1,
      [ChainId.METER_TEST]: 1,
      [ChainId.THUNDERCORE]: 1,
      [ChainId.DC_TEST]: 1,
      [ChainId.VELAS]: 1,
    }),
  },
}
export function isLegacySwapABIPool(poolName: string): boolean {
  return new Set([BTC_POOL_NAME, VETH2_POOL_NAME]).has(poolName)
}
export function isMetaPool(poolName = ""): boolean {
  return new Set([
    FRAX_OPT_USD_METAPOOL_NAME,
    SUSD_METAPOOL_NAME,
    SUSD_METAPOOL_V2_NAME,
    TBTC_METAPOOL_NAME,
    TBTC_METAPOOL_V2_NAME,
    WCUSD_METAPOOL_NAME,
    WCUSD_METAPOOL_V2_NAME,
  ]).has(poolName)
}

// maps a symbol string to a token object
export type TokensMap = {
  [symbol: string]: Token
}

export const TOKENS_MAP = Object.keys(POOLS_MAP).reduce((acc, poolName) => {
  const pool = POOLS_MAP[poolName as PoolName]
  const newAcc = { ...acc }
  pool.poolTokens.forEach((token) => {
    newAcc[token.symbol] = token
  })
  newAcc[pool.lpToken.symbol] = pool.lpToken
  return newAcc
}, {} as TokensMap)

export type TokenToPoolsMap = {
  [tokenSymbol: string]: string[]
}
export const TOKEN_TO_POOLS_MAP = Object.keys(POOLS_MAP).reduce(
  (acc, poolName) => {
    const pool = POOLS_MAP[poolName as PoolName]
    const newAcc = { ...acc }
    pool.poolTokens.forEach((token) => {
      newAcc[token.symbol] = (newAcc[token.symbol] || []).concat(
        poolName as PoolName,
      )
    })
    return newAcc
  },
  {} as TokenToPoolsMap,
)

export type LPTokenToPoolsMap = {
  [tokenSymbol: string]: PoolName
}
export const LPTOKEN_TO_POOL_MAP = Object.keys(POOLS_MAP).reduce(
  (acc, poolName) => {
    const pool = POOLS_MAP[poolName as PoolName]
    const newAcc = { ...acc }
    newAcc[pool.lpToken.symbol] = poolName as PoolName
    return newAcc
  },
  {} as LPTokenToPoolsMap,
)

export const TRANSACTION_TYPES = {
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  SWAP: "SWAP",
  MIGRATE: "MIGRATE",
  STAKE_OR_CLAIM: "STAKE_OR_CLAIM",
}

export const POOL_FEE_PRECISION = 10

export enum SWAP_TYPES {
  DIRECT = "swapDirect", // route length 2
  SYNTH_TO_SYNTH = "swapSynthToSynth", // route length 2
  SYNTH_TO_TOKEN = "swapSynthToToken", // route length 3
  TOKEN_TO_SYNTH = "swapTokenToSynth", // route length 3
  TOKEN_TO_TOKEN = "swapTokenToToken", // route length 4
  INVALID = "invalid",
}

export function getIsVirtualSwap(swapType: SWAP_TYPES): boolean {
  return (
    swapType === SWAP_TYPES.SYNTH_TO_SYNTH ||
    swapType === SWAP_TYPES.SYNTH_TO_TOKEN ||
    swapType === SWAP_TYPES.TOKEN_TO_SYNTH ||
    swapType === SWAP_TYPES.TOKEN_TO_TOKEN
  )
}

export const SWAP_CONTRACT_GAS_ESTIMATES_MAP = {
  [SWAP_TYPES.INVALID]: BigNumber.from("999999999"), // 999,999,999
  [SWAP_TYPES.DIRECT]: BigNumber.from("200000"), // 157,807
  [SWAP_TYPES.TOKEN_TO_TOKEN]: BigNumber.from("2000000"), // 1,676,837
  [SWAP_TYPES.TOKEN_TO_SYNTH]: BigNumber.from("2000000"), // 1,655,502
  [SWAP_TYPES.SYNTH_TO_TOKEN]: BigNumber.from("1500000"), // 1,153,654
  [SWAP_TYPES.SYNTH_TO_SYNTH]: BigNumber.from("700000"), // 681,128 // TODO: https://github.com/jio-finance/jio-frontend/issues/471
  addLiquidity: BigNumber.from("400000"), // 386,555
  removeLiquidityImbalance: BigNumber.from("350000"), // 318,231
  removeLiquidityOneToken: BigNumber.from("250000"), // 232,947
  migrate: BigNumber.from("650000"), // 619,126
  virtualSwapSettleOrWithdraw: BigNumber.from("400000"),
}

export interface WalletInfo {
  name: string
  icon: string
  connector: AbstractConnector
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  TALLY: {
    name: "Tally",
    icon: tallyIcon,
    connector: injectedTallyProvider,
  },
  METAMASK: {
    name: "MetaMask",
    icon: metamaskIcon,
    connector: injectedMetaMaskProvider,
  },
  WALLET_CONNECT: {
    name: "WalletConnect",
    icon: walletconnectIcon,
    connector: walletconnect,
  },
  WALLET_LINK: {
    name: "Coinbase Wallet",
    icon: coinbasewalletIcon,
    connector: walletlink,
  },
}

// "JIO" in bytes32 form
export const SYNTH_TRACKING_ID =
  "0x4a494f0000000000000000000000000000000000000000000000000000000000"

export const GW_V1_1_GAS = 780000

// FLAGS
export const IS_VIRTUAL_SWAP_ACTIVE = false
export const IS_L2_SUPPORTED = true
export const IS_JIO_LIVE = true
// FLAGS END
export const readableDecimalNumberRegex = /^[0-9]*[.,]?[0-9]*$/
