import "./ConfirmTransaction.scss"

import React, { ReactElement } from "react"
import loadingGif from "../assets/loading.gif"
import logo from "../assets/icons/logo-top.png"
import { useTranslation } from "react-i18next"

function ConfirmTransaction(): ReactElement {
  const { t } = useTranslation()

  //Todo

  return (
    <div className="confirmTransaction">
      <img src={logo} alt="logo" className="logo" />
      <h3>{t("confirmTransaction")}</h3>
      <img src={loadingGif} alt="loading..." className="loadingGif" />
    </div>
  )
}

export default ConfirmTransaction
