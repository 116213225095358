import { ChainId } from "./index"

export const NETWORK_LABEL: Partial<Record<ChainId, string>> = {
  [ChainId.DC_TEST]: "DOGECHAIN_TEST",
  [ChainId.EMERALD_TEST]: "EMERALD_TEST",
  [ChainId.GW_V1_1]: "GodWokenV1_1",
  [ChainId.BSC_TEST]: "BscTest",
  [ChainId.BSC_MAIN]: "Binance",
  [ChainId.FTM_MAIN]: "Fantom",
  [ChainId.FTM_TEST]: "FTMTEST",
  [ChainId.ARBITRUM]: "Arbitrum",
  [ChainId.ROPSTEN]: "Ropsten",
  [ChainId.HARDHAT]: "Hardhat 👷🏼‍♂️",
  [ChainId.ETHEREUM]: "Ethereum",
  [ChainId.OPTIMISM]: "Optimism",
  [ChainId.AURORA]: "AURORA",
  [ChainId.AURORA_TEST]: "AURORA_TEST",
  [ChainId.METER_TEST]: "METER_TEST",
  [ChainId.THUNDERCORE]: "THUNDERCORE_TEST",
  [ChainId.METER]: "METER",
  [ChainId.VELAS]: "VELAS_TEST",
}
