/* eslint-disable sort-imports */
import {
  Box,
  Button,
  DialogContent,
  Link,
  Stack,
  Typography,
} from "@mui/material"
import React, { ReactElement } from "react"
import { commify, formatBNToString } from "../utils"
import ChangeIcon from "@mui/icons-material/ImportExport"
import Copy from "./Copy"
import Davatar from "@davatar/react"
import DialogTitle from "./DialogTitle"
import LaunchIcon from "@mui/icons-material/Launch"
import Transactions from "./Transactions"
import { SUPPORTED_WALLETS } from "../constants"
import { find } from "lodash"
import { getMultichainScanLink } from "../utils/getEtherscanLink"
import { shortenAddress } from "../utils/shortenAddress"
import { useActiveWeb3React } from "../hooks"
import { usePoolTokenBalances } from "../state/wallet/hooks"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import { Zero } from "@ethersproject/constants"

interface Props {
  openOptions: () => void
  onClose?: () => void
}

export default function AccountDetail({
  openOptions,
  onClose,
}: Props): ReactElement {
  const { t } = useTranslation()
  const { account, connector, chainId } = useActiveWeb3React()
  const tokenBalances = usePoolTokenBalances()
  const ethBalanceFormatted = commify(
    formatBNToString(tokenBalances?.ETH || Zero, 18, 6),
  )
  const connectorName = find(SUPPORTED_WALLETS, ["connector", connector])?.name
  const theme = useTheme()
  return (
    <Box
      data-testid="accountDetailContainer"
      bgcolor={
        theme.palette.mode === "light"
          ? theme.palette.common.white
          : theme.palette.common.black
      }
    >
      <DialogTitle onClose={onClose}>
        <Typography variant="h3">{t("account")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="auto auto"
          rowGap={2}
        >
          <Typography variant="body2" color="text.secondary">
            {t("connectedWith")}&nbsp;
            {connectorName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t("balance")}
          </Typography>

          <Stack direction="row" spacing={1}>
            <Davatar
              size={24}
              address={account || ""}
              generatedAvatarType="jazzicon"
            />
            <Typography variant="subtitle1">
              {account && shortenAddress(account)}
            </Typography>
            {chainId && account && (
              <Link
                href={getMultichainScanLink(chainId, account, "address")}
                target="_blank"
                rel="noreferrer"
              >
                <LaunchIcon fontSize="inherit" />
              </Link>
            )}
          </Stack>
          <Box>
            <Typography variant="subtitle1">
              {ethBalanceFormatted}&#926;
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            {account && <Copy toCopy={account} />}
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              onClick={() => {
                openOptions()
              }}
              startIcon={<ChangeIcon />}
              data-testid="changeAccountBtn"
            >
              {t("changeAccount")}
            </Button>
          </Box>
        </Box>
      </DialogContent>
      {/* Change hardcoded color with theme color after definining design */}
      <Box
        bgcolor={
          theme.palette.mode == "light"
            ? theme.palette.background.paper
            : "#333"
        }
        p={3}
      >
        <Transactions />
      </Box>
    </Box>
  )
}
