import "./Stake.scss"

// import { Container, Typography } from "@mui/material"
import React, { useMemo, useState, useEffect, ReactElement } from "react"
import { BigNumber } from "@ethersproject/bignumber"
import { Zero } from "@ethersproject/constants"
import StakePage from "../components/StakePage"
import { useActiveWeb3React } from "../hooks"
import { usePoolTokenBalances } from "../state/wallet/hooks"
import usePoolsStatuses from "../hooks/usePoolsStatuses"
// import {
//   useBridgeContract,
//   useSynthetixExchangeRatesContract,
// } from "../hooks/useContract"
import {
  SwapData,
  SwapSide,
  // useCalculateSwapPairs,
} from "../hooks/useCalculateSwapPairs"

// import { useTranslation } from "react-i18next"
import { AppState } from "../state"
import { useSelector } from "react-redux"
import {
  IS_VIRTUAL_SWAP_ACTIVE,
  PoolName,
  SWAP_TYPES,
  TOKENS_MAP,
  TOKEN_TO_POOLS_MAP,
} from "../constants"
import { calculatePrice, shiftBNDecimals } from "../utils"
import { formatUnits } from "ethers/lib/utils"
import { TokenOption } from "./Swap"

type FormState = {
  error: null | string
  from: {
    value: string
    valueUSD: BigNumber
  } & SwapSide
  to: {
    value: BigNumber
    valueUSD: BigNumber
    valueSynth: BigNumber
  } & SwapSide
  priceImpact: BigNumber
  exchangeRate: BigNumber
  route: string[]
  swapType: SWAP_TYPES
  currentSwapPairs: SwapData[]
}

const EMPTY_FORM_STATE = {
  error: null,
  from: {
    symbol: "",
    value: "0.0",
    valueUSD: Zero,
  },
  to: {
    symbol: "",
    value: Zero,
    valueUSD: Zero,
    valueSynth: Zero,
  },
  priceImpact: Zero,
  exchangeRate: Zero,
  route: [],
  swapType: SWAP_TYPES.INVALID,
  currentSwapPairs: [],
}

function Stake(): ReactElement {
  // const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const tokenBalances = usePoolTokenBalances()
  const poolsStatuses = usePoolsStatuses()
  // const bridgeContract = useBridgeContract()
  // const snxEchangeRatesContract = useSynthetixExchangeRatesContract()
  // const calculateSwapPairs = useCalculateSwapPairs()

  const { tokenPricesUSD } = useSelector((state: AppState) => state.application)
  // const { gasPriceSelected, gasCustom } = useSelector(
  //   (state: AppState) => state.user,
  // )

  const [formState, setFormState] = useState<FormState>(EMPTY_FORM_STATE)
  const [prevFormState, setPrevFormState] =
    useState<FormState>(EMPTY_FORM_STATE)
  useEffect(() => {
    setFormState(EMPTY_FORM_STATE)
    setPrevFormState(EMPTY_FORM_STATE)
    console.log(prevFormState)
  }, [chainId, prevFormState])

  // build a representation of pool tokens for the UI
  const tokenOptions = useMemo(() => {
    if (!chainId)
      return {
        from: [],
        to: [],
      }
    const allTokens = Object.values(TOKENS_MAP)
      .filter(({ isLPToken, addresses }) => !isLPToken && addresses[chainId])
      .filter(({ symbol }) => {
        // get list of pools containing the token
        const tokenPools = TOKEN_TO_POOLS_MAP[symbol]
        // ensure at least one pool is unpaused to include token in swappable list
        const hasAnyUnpaused = tokenPools.reduce((acc, poolName) => {
          const poolStatus = poolsStatuses[poolName as PoolName]
          return poolStatus ? Boolean(acc || !poolStatus.isPaused) : acc
        }, false)
        return hasAnyUnpaused
      })
      .map(({ symbol, name, icon, decimals }) => {
        const amount = tokenBalances?.[symbol] || Zero
        return {
          name,
          icon,
          symbol,
          decimals,
          amount,
          valueUSD: calculatePrice(amount, tokenPricesUSD?.[symbol], decimals),
          isAvailable: true,
          swapType: null,
        }
      })
      .sort(sortTokenOptions)
    const toTokens =
      formState.currentSwapPairs.length > 0
        ? formState.currentSwapPairs
            .map(({ to, type: swapType }) => {
              const { symbol, name, icon, decimals } = TOKENS_MAP[to.symbol]
              const amount = tokenBalances?.[symbol] || Zero
              return {
                name,
                icon,
                symbol,
                decimals,
                amount,
                valueUSD: calculatePrice(
                  amount,
                  tokenPricesUSD?.[symbol],
                  decimals,
                ),
                swapType,
                isAvailable: IS_VIRTUAL_SWAP_ACTIVE
                  ? swapType !== SWAP_TYPES.INVALID
                  : swapType === SWAP_TYPES.DIRECT, // TODO replace once VSwaps are live
              }
            })
            .sort(sortTokenOptions)
        : allTokens
    // from: all tokens always available. to: limited by selected "from" token.
    return {
      from: allTokens,
      to: toTokens,
    }
  }, [
    tokenPricesUSD,
    tokenBalances,
    formState.currentSwapPairs,
    chainId,
    poolsStatuses,
  ])

  function handleUpdateAmountFrom(value: string): void {
    setFormState((prevState) => {
      const nextState = {
        ...prevState,
        to: {
          ...prevState.to,
          valueUSD: Zero,
          valueSynth: Zero,
        },
        from: {
          ...prevState.from,
          value,
          valueUSD: calculatePrice(
            value,
            tokenPricesUSD?.[prevState.from.symbol],
          ),
        },
        priceImpact: Zero,
        exchangeRate: Zero,
      }
      return nextState
    })
  }

  function handleUpdateTokenFrom(symbol: string): void {
    setFormState((prevState) => {
      const nextState = {
        ...prevState,
        error: null,
        from: {
          ...prevState.from,
          symbol,
          valueUSD: calculatePrice(
            prevState.from.value,
            tokenPricesUSD?.[symbol],
          ),
        },
        to: {
          ...prevState.to,
          value: Zero,
          valueSynth: Zero,
          valueUSD: Zero,
        },
        priceImpact: Zero,
        exchangeRate: Zero,
      }
      return nextState
    })
  }

  return (
    // <div className="stakepage">
    //   <Container maxWidth="md" sx={{ mb: 16 }}>
    //     <Typography variant="h3" mt={5} mb={2}>
    //       Jbox Page...
    //     </Typography>
    //   </Container>
    // </div>
    <StakePage
      tokenOptions={tokenOptions}
      fromState={formState.from}
      toState={{
        ...formState.to,
        value:
          formState.to.symbol === ""
            ? "0"
            : formatUnits(
                formState.to.value,
                TOKENS_MAP[formState.to.symbol].decimals,
              ),
      }}
      onChangeFromAmount={handleUpdateAmountFrom}
      onChangeFromToken={handleUpdateTokenFrom}
      error={formState.error}
    />
  )
}

export default Stake

const sortTokenOptions = (a: TokenOption, b: TokenOption) => {
  if (a.isAvailable !== b.isAvailable) {
    return a.isAvailable ? -1 : 1
  }
  // if either is invalid, put the valid one first
  if (a.swapType === SWAP_TYPES.INVALID || b.swapType === SWAP_TYPES.INVALID) {
    return a.swapType === SWAP_TYPES.INVALID ? 1 : -1
  }
  if (a.valueUSD.gt(b.valueUSD)) {
    // prefer largest wallet balance
    return -1
  } else if (a.valueUSD.gt(Zero) && a.valueUSD.eq(b.valueUSD)) {
    const amountA = shiftBNDecimals(a.amount, 18 - a.decimals)
    const amountB = shiftBNDecimals(b.amount, 18 - b.decimals)
    return amountA.gt(amountB) ? -1 : 1
  }
  // prefer direct swaps
  return a.swapType === SWAP_TYPES.DIRECT ? -1 : 1
}
