import { ChainId } from "../constants"

export function getGraphLink(chainId: ChainId): string {
  if (chainId === ChainId.GW_V1_1) {
    return "https://graph-gw.hostsites.ca/subgraphs/name/jioswap-finance/jioswap"
  } else if (chainId === ChainId.THUNDERCORE) {
    return "https://graph-gw.hostsites.ca/subgraphs/name/jioswap-finance/jioswap-thunder"
  } else if (chainId === ChainId.DC_TEST) {
    return "https://graph-gw.hostsites.ca/subgraphs/name/jioswap-finance/jioswap-dc"
  }
  return ""
}
