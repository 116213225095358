import { ChainId, IS_JIO_LIVE, IS_L2_SUPPORTED, JIO_TOKEN } from "../constants"
import React, { ReactElement, useState } from "react"

import classnames from "classnames"
import logo from "../assets/icons/logo.svg"
import styles from "./SiteSettingsMenu.module.scss"
import { useActiveWeb3React } from "../hooks"
import useAddTokenToMetamask from "../hooks/useAddTokenToMetamask"
import { useThemeSettings } from "../providers/ThemeSettingsProvider"
import { useTranslation } from "react-i18next"

export default function SiteSettingsMenu(): ReactElement {
  return (
    <div data-testid="settingsMenuContainer" className={styles.container}>
      {IS_L2_SUPPORTED && <NetworkSection key="network" />}
      {IS_L2_SUPPORTED && <Divider />}
      <LanguageSection key="language" />
      <Divider />
      <ThemeSection key="theme" />
      {IS_JIO_LIVE && <Divider />}
      {IS_JIO_LIVE && <AddTokenSection key="token" />}
    </div>
  )
}

function Divider(): ReactElement {
  return <div className={styles.divider}></div>
}

function AddTokenSection(): ReactElement | null {
  const { addToken, canAdd } = useAddTokenToMetamask({
    ...JIO_TOKEN,
    icon: `${window.location.origin}/logo.svg`,
  })
  const { t } = useTranslation()

  return canAdd ? (
    <div className={styles.section}>
      <div className={styles.sectionTitle} onClick={() => addToken()}>
        <span>{t("addJIO")}</span> <img src={logo} className={styles.logo} />
      </div>
    </div>
  ) : null
}

// refer to https://github.com/sushiswap/sushiswap-interface/blob/canary/src/modals/NetworkModal/index.tsx#L13
export const SUPPORTED_NETWORKS: {
  [chainId in ChainId]?: {
    chainId: string
    chainName: string
    nativeCurrency: {
      name: string
      symbol: string
      decimals: number
    }
    rpcUrls: string[]
    blockExplorerUrls: string[]
  }
} = {
  [ChainId.BSC_TEST]: {
    chainId: "0x61",
    chainName: "BSCTEST",
    nativeCurrency: {
      name: "BSCTEST",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s3.binance.org:8545"],
    blockExplorerUrls: ["https://testnetbscscan.com"],
  },
  [ChainId.FTM_TEST]: {
    chainId: "0xfa2",
    chainName: "FTM_TEST",
    nativeCurrency: {
      name: "FTM_TEST",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.testnet.fantom.network/"],
    blockExplorerUrls: ["https://testnet.ftmscan.com"],
  },
  [ChainId.ETHEREUM]: {
    chainId: "0x1",
    chainName: "Ethereum",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3"],
    blockExplorerUrls: ["https://etherscan.com"],
  },
  [ChainId.FTM_MAIN]: {
    chainId: "0xFA",
    chainName: "Fantom",
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.ftm.tools/"],
    blockExplorerUrls: ["https://ftmscan.com"],
  },
  [ChainId.BSC_MAIN]: {
    chainId: "0x38",
    chainName: "Binance",
    nativeCurrency: {
      name: "Binance",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [ChainId.GW_V1_1]: {
    chainId: "0x116e9",
    chainName: "Godwoken_V1_1",
    nativeCurrency: {
      name: "Godwoken_V1_1",
      symbol: "pCKB",
      decimals: 18,
    },
    rpcUrls: ["https://godwoken-testnet-v1.ckbapp.dev"],
    blockExplorerUrls: ["https://v1.betanet.gwscan.com"],
  },
  [ChainId.VELAS]: {
    chainId: "0x6F",
    chainName: "VELAS_TEST",
    nativeCurrency: {
      name: "VELAS_TEST",
      symbol: "VLX",
      decimals: 18,
    },
    rpcUrls: ["https://evmexplorer.testnet.velas.com/rpc"],
    blockExplorerUrls: ["https://evmexplorer.testnet.velas.com"],
  },
  [ChainId.OPTIMISM]: {
    chainId: "0xA",
    chainName: "Optimism",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.optimism.io"],
    blockExplorerUrls: ["https://optimistic.etherscan.io"],
  },
  [ChainId.AURORA_TEST]: {
    chainId: "0x4e454153",
    chainName: "AURORA_TEST",
    nativeCurrency: {
      name: "AURORA_TEST",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://testnet.aurora.dev/"],
    blockExplorerUrls: ["https://testnet.aurorascan.dev/"],
  },
  [ChainId.AURORA]: {
    chainId: "0x4e454152",
    chainName: "AURORA",
    nativeCurrency: {
      name: "AURORA",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.aurora.dev"],
    blockExplorerUrls: ["https://aurorascan.dev/"],
  },
  [ChainId.EMERALD_TEST]: {
    chainId: "0xa515",
    chainName: "EMERALD_TEST",
    nativeCurrency: {
      name: "EMERALD_TEST",
      symbol: "ROSE",
      decimals: 18,
    },
    rpcUrls: ["https://testnet.emerald.oasis.dev"],
    blockExplorerUrls: ["https://testnet.explorer.emerald.oasis.dev"],
  },
  [ChainId.METER_TEST]: {
    chainId: "0x53",
    chainName: "METER_TEST",
    nativeCurrency: {
      name: "METER_TEST",
      symbol: "MTR",
      decimals: 18,
    },
    rpcUrls: ["https://rpctest.meter.io"],
    blockExplorerUrls: ["https://scan-warringstakes.meter.io"],
  },
  [ChainId.METER]: {
    chainId: "0x52",
    chainName: "METER",
    nativeCurrency: {
      name: "METER",
      symbol: "MTR",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.meter.io"],
    blockExplorerUrls: ["https://scan.meter.io"],
  },
  [ChainId.RINKEBY]: {
    chainId: "0x4",
    chainName: "RINKEBY",
    nativeCurrency: {
      name: "RINKEBY",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://rinkeby.infura.io/v3/"],
    blockExplorerUrls: ["https://rinkeby.etherscan.io"],
  },
  [ChainId.THUNDERCORE]: {
    chainId: "0x12",
    chainName: "THUNDERCORE_TEST",
    nativeCurrency: {
      name: "THUNDERCORE_TEST",
      symbol: "TST",
      decimals: 18,
    },
    rpcUrls: ["https://testnet-rpc.thundercore.com"],
    blockExplorerUrls: ["https://explorer-testnet.thundercore.com"],
  },
  [ChainId.DC_TEST]: {
    chainId: "0x238",
    chainName: "DOGECHAIN_TEST",
    nativeCurrency: {
      name: "DOGECHAIN_TEST",
      symbol: "DOGE",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-testnet.dogechain.dog"],
    blockExplorerUrls: ["https://explorer-testnet.dogechain.dog"],
  },
}
function NetworkSection(): ReactElement {
  const { t } = useTranslation()
  const { chainId: activeChainId, library, account } = useActiveWeb3React()
  const [isNetworkVisible, setIsNetworkVisible] = useState(false)
  const networks = [
    ChainId.BSC_TEST,
    ChainId.FTM_TEST,
    ChainId.THUNDERCORE,
    ChainId.DC_TEST,
    ChainId.VELAS,

    ...(IS_L2_SUPPORTED
      ? [
          ChainId.GW_V1_1,
          ChainId.EMERALD_TEST,
          ChainId.AURORA_TEST,
          ChainId.METER_TEST,
          ChainId.METER,
        ]
      : []),
  ]

  return (
    <div data-testid="networkMenuContainer" className={styles.section}>
      <div
        data-testid="networkMenuTitle"
        className={styles.sectionTitle}
        onClick={() => setIsNetworkVisible((state) => !state)}
      >
        <span>{t("network")}</span> <span>{isNetworkVisible ? "∧" : "∨"}</span>
      </div>
      {isNetworkVisible &&
        networks.map((chainId) => {
          const params = SUPPORTED_NETWORKS[chainId]

          return (
            <div
              className={classnames(styles.sectionItem, {
                [styles.active]: activeChainId === chainId,
              })}
              onClick={() => {
                if (chainId === ChainId.BSC_TEST) {
                  void library?.send("wallet_switchEthereumChain", [
                    { chainId: "0x61" },
                    account,
                  ])
                } else {
                  void library?.send("wallet_addEthereumChain", [
                    params,
                    account,
                  ])
                }
              }}
              key={chainId}
            >
              {params?.chainName}
            </div>
          )
        })}
    </div>
  )
}

function LanguageSection(): ReactElement {
  const { t, i18n } = useTranslation()

  const [isLanguageVisible, setIsLanguageVisible] = useState(false)
  const languageOptions = [
    { displayText: "English", i18nKey: "en" },
    { displayText: "Français", i18nKey: "fr" },
    { displayText: "简体中文", i18nKey: "zh" },
  ]
  const currentLanguage = i18n.language
  return (
    <div data-testid="languageMenu" className={styles.section}>
      <div
        className={styles.sectionTitle}
        onClick={() => setIsLanguageVisible((state) => !state)}
      >
        <span>{t("language")}</span>{" "}
        <span>{isLanguageVisible ? "∧" : "∨"}</span>
      </div>
      {isLanguageVisible &&
        languageOptions.map(({ displayText, i18nKey }) => (
          <div
            className={classnames(styles.sectionItem, {
              [styles.active]: currentLanguage === i18nKey,
            })}
            onClick={() => i18n.changeLanguage(i18nKey)}
            key={displayText}
          >
            {displayText}
          </div>
        ))}
    </div>
  )
}

function ThemeSection(): ReactElement {
  const { t } = useTranslation()
  const { themeMode, onChangeMode } = useThemeSettings()

  const handleChangeMode = () => {
    onChangeMode(themeMode === "dark" ? "light" : "dark")
  }

  return (
    <div className={styles.section}>
      <div
        data-testid="themeMenuOption"
        className={styles.sectionTitle}
        onClick={handleChangeMode}
      >
        <span>{t("theme")}</span>{" "}
        <span>{themeMode === "dark" ? "☾" : "☀"}</span>
      </div>
    </div>
  )
}
