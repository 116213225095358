import { ChainId, D4_POOL_NAME } from "../constants"
import { Dialog, DialogContent, Link, Typography } from "@mui/material"
import React, { ComponentProps, ReactElement, useMemo } from "react"
import { useActiveWeb3React } from "../hooks"
import usePoolData from "../hooks/usePoolData"
import { useTranslation } from "react-i18next"

interface Props {
  open?: boolean
}

interface SerializedEthereumRpcError {
  error: {
    code: number // must be an integer
    message: string
    data?: unknown
    stack?: string
  }
}

function CustomLink({
  text,
  style,
  ...props
}: ComponentProps<typeof Link> & { text: string }) {
  return (
    <Link
      style={{
        justifyContent: "center",
        borderRadius: 8,
        alignItems: "center",
        boxShadow: "0 10px 20px rgba(219, 14, 202, .4)",
        backgroundImage: "linear-gradient(315deg, #9921e8 0%, #5f72be 74%)",
        width: 89,
        height: 49,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        textDecoration: "none",
        position: "relative",
        ...style,
      }}
      {...props}
    >
      {text}
    </Link>
  )
}

export default function NoAccountModal({ open }: Props): ReactElement {
  const [, , jsonRpcError] = usePoolData(D4_POOL_NAME)
  const { chainId } = useActiveWeb3React()

  const hasNoAccount = useMemo(() => {
    if (!jsonRpcError) return false
    return (
      (jsonRpcError as SerializedEthereumRpcError)?.error?.code === -32603 &&
      chainId === ChainId.GW_V1_1
    )
  }, [jsonRpcError, chainId])

  const { t } = useTranslation()

  return (
    <Dialog open={open ?? hasNoAccount} maxWidth="xs">
      <DialogContent sx={{ whiteSpace: "pre-line" }}>
        <Typography textAlign="center" mb={3} sx={{ fontSize: 48 }}>
          &#128586;
        </Typography>
        <CustomLink
          href="https://testnet.bridge.godwoken.io"
          target="_blank"
          text="Create Account"
        />
        <Typography style={{ paddingTop: 30 }}>{t("noAccount")}</Typography>
      </DialogContent>
    </Dialog>
  )
}
