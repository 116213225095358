import { Components, Theme } from "@mui/material"

export default function ButtonTheme(theme: Theme): Components {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: theme.palette.text.secondary,
          border: ` 1px solid ${theme.palette.other.divider}`,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  }
}
