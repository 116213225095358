import "./StakePage.scss"
import "./Button.scss"

import { IS_JIO_LIVE, PoolName } from "../constants"
import { BigNumber } from "@ethersproject/bignumber"
import { Box } from "@mui/material"
import React, { ReactElement, useState, useMemo } from "react"
import { Zero } from "@ethersproject/constants"
import { useTranslation } from "react-i18next"
import { formatBNToString, commify } from "../utils"
import { useRewardsHelpers } from "../hooks/useRewardsHelpers"
import Button from "./Button"
import SwapInput from "./SwapInput"
import { TokenOption } from "../pages/Swap"

interface Props {
  tokenOptions: {
    from: TokenOption[]
    to: TokenOption[]
  }
  error: string | null
  fromState: { symbol: string; value: string; valueUSD: BigNumber }
  toState: { symbol: string; value: string; valueUSD: BigNumber }
  onChangeFromToken: (tokenSymbol: string) => void
  onChangeFromAmount: (amount: string) => void
}

const StakePage = (props: Props): ReactElement => {
  const { approveAndStake, unstake, amountStaked, isPoolIncentivized } =
    useRewardsHelpers("D4_POOL_NAME" as PoolName) // TESTING WITH STABEL_COIN_V2
  const { t } = useTranslation()

  const [stakeInput, setStakeInput] = useState<string>("60")
  const handleStakeInput = (value: string) => {
    setStakeInput(value)
  }

  const {
    tokenOptions,
    fromState,
    toState,
    onChangeFromToken,
    onChangeFromAmount,
  } = props

  const fromToken = useMemo(() => {
    return tokenOptions.from.find(({ symbol }) => symbol === fromState.symbol)
  }, [tokenOptions.from, fromState.symbol])

  const formattedWalletBalance = commify(
    formatBNToString(fromToken?.amount || Zero, fromToken?.decimals || 0, 6),
  )

  const formattedStakedBalance = commify(formatBNToString(amountStaked, 18, 4))

  console.log(isPoolIncentivized)
  return IS_JIO_LIVE ? (
    <div className="stakePage">
      <div className="content">
        <div className="stakeForm glow">
          <div className="row">
            <h3 className="stakeTitle">JIO Stake LP</h3>
          </div>
          <div className="row">
            <h3 className="subTitle">{t("Earning 28.5% in APY")}</h3>
          </div>

          <div style={{ height: "1vh" }}></div>

          <div className="row">
            <h4 className="stakeHeader">{t("Stake")}</h4>
            <div className="balanceContainer">
              <span>{t("balance")}:</span>
              &nbsp;
              <a
                onClick={() => {
                  if (fromToken == null) return
                  const amtStr = formatBNToString(
                    fromToken.amount,
                    fromToken.decimals || 0,
                  )
                  onChangeFromAmount(amtStr)
                }}
              >
                {formattedWalletBalance}
              </a>
            </div>
          </div>

          <div className="row">
            <SwapInput
              tokens={tokenOptions.from.filter(
                ({ symbol }) => symbol !== toState.symbol,
              )}
              onSelect={onChangeFromToken}
              onChangeAmount={onChangeFromAmount}
              selected={fromState.symbol}
              inputValue={fromState.value}
              inputValueUSD={fromState.valueUSD}
              isSwapFrom={true}
            />

            <button
              className="button stakeBtn secondary"
              disabled={fromState.valueUSD.isZero()}
              onClick={() => approveAndStake(fromState.valueUSD)}
            >
              {t("Stake")}
            </button>
          </div>

          <div style={{ height: "2vh" }}></div>

          <div className="row">
            <h4 className="stakeHeader">{t("Unstake")}</h4>
            <div className="balanceContainer">
              <span>{t("Staked")}:</span>
              &nbsp;
              <a>{formattedStakedBalance}</a>
            </div>
          </div>

          <div className="row">
            <SwapInput
              tokens={tokenOptions.from.filter(
                ({ symbol }) => symbol !== fromState.symbol,
              )}
              selected={"JIO"}
              onChangeAmount={handleStakeInput}
              inputValue={stakeInput}
              inputValueUSD={BigNumber.from(stakeInput)}
              isSwapFrom={true}
            />

            <button
              className="button stakeBtn secondary"
              disabled={amountStaked.isZero()}
              onClick={() => unstake(amountStaked)}
            >
              {t("Unstake")}
            </button>
          </div>

          <div style={{ height: "1vh" }}></div>
        </div>

        <Box mt={3}>
          <Button
            kind="primary"
            onClick={(): void => {
              console.log("Button clicked")
            }}
            disabled={true}
            size="large"
          >
            {t("Claim 0.0 JIO")}
          </Button>
        </Box>
      </div>
    </div>
  ) : (
    <h2>Pool Is Not Incentivized or Jio Not Live</h2>
  )
}

export default StakePage
