import { ChainId } from "../constants"

export function getMultichainScanLink(
  chainId: ChainId,
  data: string,
  type: "tx" | "token" | "address" | "block",
): string {
  if (chainId === ChainId.GW_V1_1) {
    return `https://v1.betanet.gwscan.com/${type}/${data}`
  } else if (chainId === ChainId.FTM_TEST) {
    return `https:///testnet.ftmscan.com/${type}/${data}`
  } else if (chainId === ChainId.VELAS) {
    return `https://evmexplorer.testnet.velas.com/${type}/${data}`
  } else if (chainId === ChainId.METER_TEST) {
    return `https://scan-warringstakes.meter.io/${type}/${data}`
  } else if (chainId === ChainId.THUNDERCORE) {
    return `https://explorer-testnet.thundercore.com/${type}/${data}`
  } else if (chainId === ChainId.DC_TEST) {
    return `https://explorer-testnet.dogechain.dog/${type}/${data}`
  } else if (chainId === ChainId.METER) {
    return `https://https://scan.meter.io/${type}/${data}`
  } else if (chainId === ChainId.AURORA_TEST) {
    return `https://testnet.aurorascan.dev/${type}/${data}`
  } else if (chainId === ChainId.EMERALD_TEST) {
    return `https://testnet.explorer.emerald.oasis.dev/${type}/${data}`
  } else {
    let chainScanBaseName = "etherscan"
    switch (chainId) {
      case ChainId.ETHEREUM:
        chainScanBaseName = "etherscan"
        break
      case ChainId.ARBITRUM:
        chainScanBaseName = "arbiscan"
        break
      case ChainId.FTM_MAIN:
        chainScanBaseName = "ftmscan"
        break
      case ChainId.OPTIMISM:
        chainScanBaseName = "optimistic.etherscan"
        break
      case ChainId.RINKEBY:
        chainScanBaseName = "rinkeby.etherscan"
        break
      default:
        chainScanBaseName = "etherscan"
    }
    return `https://${chainScanBaseName}.io/${type}/${data}`
  }
}

export function getEtherscanLink(
  data: string,
  type: "tx" | "token" | "address" | "block",
): string {
  return `https:///rinkeby.etherscan.io/${type}/${data}`
}
export function getGwScanLink(
  data: string,
  type: "tx" | "token" | "address" | "block",
): string {
  return `https:///v1.aggron.gwscan.com/${type}/${data}`
}
