import "./MobileNav.scss"
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from "@mui/material"
import React, { ReactElement, useState } from "react"
import ChatIcon from "@mui/icons-material/Chat"
import CloseIcon from "@mui/icons-material/Close"
import InstagramIcon from "@mui/icons-material/Instagram"
import { ReactComponent as JioLogo } from "../../assets/icons/logo.svg"
import MenuIcon from "@mui/icons-material/Menu"
import { Link as RouterLink } from "react-router-dom"
import TwitterIcon from "@mui/icons-material/Twitter"
import Web3StatusMobile from "./Web3StatusMobile"
import { useTranslation } from "react-i18next"

function MobileNav(): ReactElement {
  const theme = useTheme()
  const { t } = useTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const colorTheme =
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.common.black
  return (
    <>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        className="mobile-nav"
      >
        <IconButton className="close" onClick={() => setOpenDrawer(false)}>
          <CloseIcon className="circle" />
        </IconButton>
        <Box style={{ textAlign: "center" }}>
          <Link
            onClick={() => setOpenDrawer(false)}
            component={RouterLink}
            to="/"
          >
            <JioLogo style={{ height: 75, width: 150 }} />
          </Link>
        </Box>
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText className="link-text">
              <Link
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  typography: "h1",
                  color: colorTheme,
                  textDecoration: "none",
                }}
                component={RouterLink}
                to="/"
              >
                {t("pools")}
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText className="link-text">
              <Link
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  typography: "h1",
                  color: colorTheme,
                  textDecoration: "none",
                }}
                component={RouterLink}
                to="/swap"
                data-testid="swapNavLink"
              >
                {t("swap")}
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText className="link-text">
              <Link
                className="linking"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  typography: "h1",
                  color: colorTheme,
                  textDecoration: "none",
                }}
                component={RouterLink}
                to="bridge"
              >
                {t("bridge")}
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          {/* <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText className="link-text">
              <a
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  color: colorTheme,
                  textDecoration: "none",
                }}
                href="https://dex.jioswap.finance"
                target="_blank"
                rel="noreferrer"
              >
                <h2>{t("jiodex")}</h2>
              </a>
            </ListItemText>
          </ListItem>
          <Divider /> */}
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText className="link-text">
              <a
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  color: colorTheme,
                  textDecoration: "none",
                }}
                href="https://faucet.jioswap.finance"
                target="_blank"
                rel="noreferrer"
              >
                <h2>{t("faucet")}</h2>
              </a>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText className="link-text">
              <Link
                className="linking"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  typography: "h1",
                  color: colorTheme,
                  textDecoration: "none",
                }}
                component={RouterLink}
                to="stake"
              >
                {t("staking")}
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <Web3StatusMobile />
          </ListItem>
          <Divider />
        </List>
        <Box className="social-links">
          <a
            href="https://twitter.com/jioswap"
            target="_blank"
            rel="noreferrer"
            style={{ paddingRight: 10 }}
          >
            <TwitterIcon />
          </a>
          <a
            href="https://instagram.com/jioswap"
            target="_blank"
            rel="noreferrer"
            style={{ paddingRight: 10 }}
          >
            <InstagramIcon />
          </a>
          <a
            href="https://discord.gg/nwp2f9FYsu"
            target="_blank"
            rel="noreferrer"
          >
            <ChatIcon />
          </a>
        </Box>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon className="menu-icon" style={{ fontSize: 35 }} />
      </IconButton>
    </>
  )
}

export default MobileNav
