import { Dialog, DialogContent, Link, Typography } from "@mui/material"
import React, { ReactElement } from "react"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"
import { useTranslation } from "react-i18next"

interface Props {
  open?: boolean
}

export default function WrongNetworkModal({ open }: Props): ReactElement {
  const { error } = useWeb3React<Web3Provider>()
  const isUnsupportChainIdError = error instanceof UnsupportedChainIdError
  const { t } = useTranslation()

  return (
    <Dialog open={open ?? isUnsupportChainIdError} maxWidth="xs">
      <DialogContent sx={{ whiteSpace: "pre-line" }}>
        <Typography textAlign="center" mb={3} sx={{ fontSize: 48 }}>
          &#128586;
        </Typography>
        <Link
          style={{
            justifyContent: "center",
            borderRadius: 8,
            alignItems: "center",
            boxShadow: "0 10px 20px rgba(219, 14, 202, .4)",
            backgroundImage: "linear-gradient(315deg, #9921e8 0%, #5f72be 74%)",
            width: 89,
            height: 49,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
            textDecoration: "none",
            position: "relative",
          }}
          href="/"
        >
          Go Back
        </Link>
        <Typography style={{ paddingTop: 30 }}>
          {t("wrongNetworkContent")}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
